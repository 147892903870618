import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { X, ExternalLink, Github } from "lucide-react";
import { Canvas } from "@react-three/fiber";
import ThreeDeeSpinner from "../../elements/ThreeDeeSpinner";
import ThreeDeeImageSpinner from "../../elements/ThreeDeeImageSpinner";

const ProjectModal = ({ project, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const project3D =
    project.icon == null ? (
      <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
      <ThreeDeeSpinner
        index={project.id}
        totalModels={10}
        model={project.model}
        initialRotation={project.initialRotation}
      />
      </Canvas>
    ) : (
      <ThreeDeeImageSpinner
        imageSrc={project.icon}
        thickness={0.5}
        rotationSpeed={{ x: 0, y: 0.01, z: 0 }}
        initialRotation={{ x: 0, y: 0, z: 0 }}
        autoRotate={true}
        scale={1}
      />
    );

  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20 backdrop-blur-xl"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute inset-0" onClick={onClose} />
      <motion.div
        className="w-full max-w-4xl h-full overflow-hidden flex flex-col relative z-10 bg-transparent"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{
          type: "spring",
          damping: 30,
          stiffness: 300,
          exit: { duration: 0.4 },
        }}
      >
        <div className="relative h-56 mx-auto">
        {project3D}
        </div>
        <motion.button
          className="absolute top-4 right-4 bg-black/50 rounded-full p-2 backdrop-blur-sm hover:bg-gray-800/50 transition-colors"
          onClick={onClose}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <X className="h-6 w-6 text-white" />
        </motion.button>
        <div className="flex-grow flex flex-col min-h-0 relative p-6 sm:p-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-white">
            {project.title}
          </h2>
          <div className="flex flex-wrap gap-2 my-6">
            {project.technologies.map((tech) => (
              <span
                key={tech}
                className="bg-gray-800 text-gray-200 px-3 py-1 rounded-full text-sm font-medium"
              >
                {tech}
              </span>
            ))}
          </div>
          <div className="flex-grow overflow-y-auto pr-4 mb-4 relative">
            <p className="text-gray-300 text-base sm:text-lg leading-relaxed mb-6">
              {project.fullDescription}
            </p>
          </div>
          <div className="flex flex-row h-16 text-small gap-4 mt-auto pt-4 border-t border-gray-700">
            {project.externalLink && (
              <motion.a
                href={project.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex w-full items-center justify-center bg-white text-black px-auto py-1 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <ExternalLink className="h-5 w-5 mr-3" />
                Visit Project
              </motion.a>
            )}
            {project.githubLink && (
              <motion.a
                href={project.githubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex w-full items-center justify-center bg-white text-black px-auto py-1 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Github className="h-5 w-5 mr-3" />
                Open GitHub
              </motion.a>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ProjectModal;
