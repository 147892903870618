import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Github, Linkedin, Mail, ExternalLink } from 'lucide-react';

const ContactCard = ({ title, icon: Icon, link, color, description }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block h-full group"
    >
      <div style={{ '--border-color': color }} className="relative overflow-hidden rounded-xl sm:rounded-2xl p-4 sm:p-7 h-full bg-white border border-gray-800 transition-all duration-300 hover:shadow-lg hover:border-[var(--border-color)]">
        <div className="relative flex sm:block">
          <div
            className="w-12 h-12 sm:w-14 sm:h-14 rounded-lg sm:rounded-xl flex-shrink-0 flex items-center justify-center sm:mb-5 mr-4 sm:mr-0 transition-transform group-hover:scale-105"
            style={{
              backgroundColor: `${color}15`,
            }}
          >
            <Icon
              size={24}
              style={{ color }}
              strokeWidth={1.5}
              className="sm:scale-110"
            />
          </div>
          <div className="flex-1">
            <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 text-gray-900">
              {title}
            </h3>
            <p className="text-gray-600 mb-4 sm:mb-5 text-sm sm:text-[15px] leading-relaxed">
              {description}
            </p>
            <div style={{ '--border-color': color }} className="inline-flex items-center text-sm font-medium text-gray-500 group-hover:text-[var(--border-color)] transition-colors">
              <span className="mr-2 text-xs sm:text-[13px] uppercase tracking-wide font-semibold">
                Connect
              </span>
              <ExternalLink size={14} strokeWidth={2} className="" />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const SectionTitle = ({ title, subtitle }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    className="text-center mb-8 sm:mb-12 px-4"
  >
    <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-3 sm:mb-4">
      {title}
    </h2>
    <p className="text-gray-600 text-base sm:text-lg mx-auto">
      {subtitle}
    </p>
  </motion.div>
);

const ContactGrid = ({ contacts }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.15 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 w-full max-w-5xl px-4 sm:px-6"
    >
      {contacts.map((contact) => (
        <motion.div
          key={contact.id}
          variants={itemVariants}
          className="relative"
        >
          <ContactCard {...contact} />
        </motion.div>
      ))}
    </motion.div>
  );
};

const ContactSection = () => {
  const { t } = useTranslation();
  const contacts = [
    {
      id: 'github',
      title: 'GitHub',
      icon: Github,
      link: 'https://github.com/angeloger',
      color: '#2D333B',
      description: t("githubSubText")
    },
    {
      id: 'linkedin',
      title: 'LinkedIn',
      icon: Linkedin,
      link: 'https://linkedin.com/in/angelo-germinario',
      color: '#0066A1',
      description: t("linkedinSubText")
    },
    {
      id: 'email',
      title: 'Email',
      icon: Mail,
      link: 'mailto:angelogerminario1@gmail.com',
      color: '#D93025',
      description: t("mailSubText")
    }
  ];

  return (
    <div className="relative min-h-[100dvh] w-full flex flex-col items-center justify-center py-8 sm:py-12">
      <div className="flex-1 w-full flex flex-col items-center justify-center">
        <SectionTitle
          title={t('contactsTitle')}
          subtitle={t('contactsSubTitle')}
        />
        <ContactGrid contacts={contacts} />
      </div>
    </div>
  );
};

export default ContactSection;