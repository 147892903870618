import React, { useRef, useMemo } from "react";
import { motion } from "framer-motion";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";
import { ArrowIcon } from "./utility-components";
import ThreeDeeSpinner from "../../elements/ThreeDeeSpinner";
import ThreeDeeImageSpinner from "../../elements/ThreeDeeImageSpinner";
import { useTranslation } from "react-i18next";
import { Camera } from "lucide-react";

const ProjectCard = ({ project, onClick }) => {
  const { t } = useTranslation();

  const project3D =
    project.icon == null ? (
      <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
      <ThreeDeeSpinner
        index={project.id}
        totalModels={10}
        model={project.model}
        initialRotation={project.initialRotation}
      />
      </Canvas>
    ) : (
      <ThreeDeeImageSpinner
        imageSrc={project.icon}
        thickness={0.5}
        rotationSpeed={{ x: 0, y: 0.01, z: 0 }}
        initialRotation={{ x: 0, y: 0, z: 0 }}
        autoRotate={true}
        scale={1}
      />
    );
  return (
    <div
      className="bg-transparent overflow-hidden cursor-pointer w-full mx-0 px-0 z-10"
      onClick={() => onClick(project)}
    >
      <div className="relative h-56 w-full mx-auto">
      {project3D}
      </div>
      <div className="p-6">
        <h3 className="text-xl mx-auto text-center font-bold mb-2 text-white">
          {project.title}
        </h3>
        <p className="text-sm text-center text-gray-300 mb-4 line-clamp-2">
          {project.description}
        </p>
        <div className="flex justify-center flex-wrap gap-2 mb-4">
          {project.technologies.slice(0, 2).map((tech) => (
            <span
              key={tech}
              className="bg-blue-900 text-blue-200 px-2 py-1 rounded-full text-xs font-medium"
            >
              {tech}
            </span>
          ))}
          {project.technologies.length > 2 && (
            <span className="bg-blue-900 text-gray-200 px-2 py-1 rounded-full text-xs font-medium">
              +{project.technologies.length - 2}
            </span>
          )}
        </div>
        <motion.div
          className="flex justify-center"
          whileHover={{ x: 5 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <span className="text-blue-500 dark:text-blue-400 inline-flex items-center">
            {t("openProject")}
            <ArrowIcon />
          </span>
        </motion.div>
      </div>
    </div>
  );
};

export default ProjectCard;
