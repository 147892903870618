import React, { useRef, useMemo, useEffect, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

const ThreeDeeSpinner = ({
  index = 1,
  totalModels = 10,
  model = "./question_mark.gltf",
  rotationSpeed = { x: 0, y: 0, z: 0.005 }, // New parameter for rotation speed
  initialRotation = { x: 90, y: 0, z: 0 }, // New parameter for initial rotation
  autoRotate = true,
}) => {
  const meshRef = useRef();
  const gltf = useLoader(GLTFLoader, model);
  const [isInitialized, setIsInitialized] = useState(false);

  const shaderMaterial = useMemo(() => {
    const offset = (index / totalModels) * Math.PI * 2;
    return new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        offset: { value: offset },
        lightPosition: { value: new THREE.Vector3(5, 5, 5) },
        ambientIntensity: { value: 0.5 },
        diffuseIntensity: { value: 0.7 },
        specularIntensity: { value: 0.5 },
        shininess: { value: 32.0 },
      },
      vertexShader: `
        varying vec3 vNormal;
        varying vec3 vViewPosition;
        void main() {
          vNormal = normalize(normalMatrix * normal);
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
          vViewPosition = -mvPosition.xyz;
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        uniform float time;
        uniform float offset;
        uniform vec3 lightPosition;
        uniform float ambientIntensity;
        uniform float diffuseIntensity;
        uniform float specularIntensity;
        uniform float shininess;
        varying vec3 vNormal;
        varying vec3 vViewPosition;
        void main() {
          vec3 baseColor = vec3(0.5) + 0.5 * cos(time + offset + vNormal.xyx + vec3(0, 2, 4));
          vec3 normal = normalize(vNormal);
          vec3 lightDir = normalize(lightPosition - vViewPosition);
          vec3 viewDir = normalize(vViewPosition);
          vec3 ambient = ambientIntensity * baseColor;
          float diff = max(dot(normal, lightDir), 0.0);
          vec3 diffuse = diffuseIntensity * diff * baseColor;
          vec3 halfDir = normalize(lightDir + viewDir);
          float spec = pow(max(dot(normal, halfDir), 0.0), shininess);
          vec3 specular = specularIntensity * spec * vec3(1.0);
          vec3 finalColor = ambient + diffuse + specular;
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `,
    });
  }, [index, totalModels]);

  const scene = useMemo(() => {
    const clonedScene = gltf.scene.clone(true);
    clonedScene.traverse((child) => {
      if (child.isMesh) {
        const newMaterial = shaderMaterial.clone();
        child.material = newMaterial;
      }
    });
    return clonedScene;
  }, [gltf.scene, shaderMaterial]);

  useEffect(() => {
    if (scene && !isInitialized) {
      scene.traverse((child) => {
        if (child.isMesh) {
          const newMaterial = shaderMaterial.clone();
          child.material = newMaterial;
        }
      });
      setIsInitialized(true);
    }

    return () => {
      scene?.traverse((child) => {
        if (child.material?.dispose) {
          child.material.dispose();
        }
      });
    };
  }, [scene, shaderMaterial, isInitialized]);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.rotation.x = initialRotation.x * (Math.PI / 180);
      meshRef.current.rotation.y = initialRotation.y * (Math.PI / 180);
      meshRef.current.rotation.z = initialRotation.z * (Math.PI / 180);
    }
  }, [initialRotation]);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    if (meshRef.current && autoRotate) {
      meshRef.current.rotation.x += rotationSpeed.x;
      meshRef.current.rotation.y += rotationSpeed.y;
      meshRef.current.rotation.z += rotationSpeed.z;

      meshRef.current.traverse((child) => {
        if (child.material?.uniforms) {
          child.material.uniforms.time.value = time;
        }
      });
    }
  });

  return (
      <primitive ref={meshRef} object={scene} scale={[0.055, 0.055, 0.055]} />
  );
};

export default ThreeDeeSpinner;
