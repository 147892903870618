import React, { useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { PerspectiveCamera } from '@react-three/drei'
import * as THREE from "three";

const createBoxWithRoundedEdges = (width, height, depth, radius) => {
  const shape = new THREE.Shape();
  const w = width / 2;
  const h = height / 2;
  
  // Create the 2D shape as before
  shape.moveTo(-w + radius, -h);
  shape.lineTo(w - radius, -h);
  shape.quadraticCurveTo(w, -h, w, -h + radius);
  shape.lineTo(w, h - radius);
  shape.quadraticCurveTo(w, h, w - radius, h);
  shape.lineTo(-w + radius, h);
  shape.quadraticCurveTo(-w, h, -w, h - radius);
  shape.lineTo(-w, -h + radius);
  shape.quadraticCurveTo(-w, -h, -w + radius, -h);

  const extrudeSettings = {
    steps: 1,
    depth: depth,
    bevelEnabled: false,
  };

  // Create the geometry
  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  
  // Center the geometry by translating all vertices
  const halfDepth = depth / 2;
  const positions = geometry.attributes.position;
  
  for (let i = 0; i < positions.count; i++) {
    positions.setZ(i, positions.getZ(i) - halfDepth);
  }
  
  // Update UVs
  const uvAttribute = geometry.attributes.uv;
  for (let i = 0; i < uvAttribute.count; i++) {
    const z = positions.getZ(i);
    if (Math.abs(z + halfDepth) < 0.001 || Math.abs(z - halfDepth) < 0.001) {
      const x = (positions.getX(i) + w) / width;
      const y = (positions.getY(i) + h) / height;
      uvAttribute.setXY(i, x, y);
    }
  }

  // Make sure to update the attributes
  positions.needsUpdate = true;
  uvAttribute.needsUpdate = true;
  
  // Recalculate normals
  geometry.computeVertexNormals();
  
  return geometry;
};

const RotatingImage = ({
  imageSrc,
  rotationSpeed = { x: 0, y: 0, z: 0 },
  initialRotation = { x: 0, y: 0, z: 0 },
  autoRotate = true,
}) => {
  const meshRef = useRef();
  const texture = useLoader(TextureLoader, imageSrc);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.rotation.x = THREE.MathUtils.degToRad(initialRotation.x);
      meshRef.current.rotation.y = THREE.MathUtils.degToRad(initialRotation.y);
      meshRef.current.rotation.z = THREE.MathUtils.degToRad(initialRotation.z);
    }
  }, [initialRotation]);

  useFrame(() => {
    if (meshRef.current && autoRotate) {
      meshRef.current.rotation.x += rotationSpeed.x;
      meshRef.current.rotation.y += rotationSpeed.y;
      meshRef.current.rotation.z += rotationSpeed.z;
    }
  });

  const aspectRatio = texture.image
    ? texture.image.width / texture.image.height
    : 1;
  const width = 7;
  const height = width / aspectRatio;
  const depth = 1.5;
  const geometry = createBoxWithRoundedEdges(width, height, depth, 2);

  const materials = [
    new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      toneMapped: false,
      emissive: new THREE.Color(0xffffff),
      emissiveMap: texture,
      emissiveIntensity: 1,
    }),
    new THREE.MeshStandardMaterial({
      map: texture,
      side: THREE.DoubleSide,
      toneMapped: false,
      emissive: new THREE.Color(0xffffff),
      emissiveMap: texture,
      emissiveIntensity: 1,
    }),
  ];

  return (
    <group ref={meshRef}>
      <mesh geometry={geometry}>
        {materials.map((material, index) => (
          <primitive
            key={index}
            object={material}
            attach={`material-${index}`}
          />
        ))}
      </mesh>
    </group>
  );
};

const ThreeDeeImage = (props) => {
  return (
    <div className="w-full h-full">
      <Canvas linear flat>
        <PerspectiveCamera
          makeDefault
          fov={35}
          position={[0, 0, 20]}
          rotation={[0, 0, 0]}
        />
        <ambientLight intensity={1} />
        <pointLight position={[10, 10, 10]} intensity={1} />
        <pointLight position={[-10, -10, -10]} intensity={0.5} />
        <RotatingImage {...props} />
      </Canvas>
    </div>
  );
};

export default ThreeDeeImage;