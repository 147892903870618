import React from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';
import { motion, AnimatePresence } from "framer-motion";

const LanguageSwitch = ({ color = "#00ffff" }) => {
  const { i18n } = useTranslation();
  
  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'it' : 'en');
  };
  
  const isItalian = i18n.language === 'it';
  
  // Convert hex to rgba for shadow
  const hexToRgba = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `${r}, ${g}, ${b}`;
  };

  return (
    <button
      onClick={toggleLanguage}
      className="fixed top-4 right-4 z-50 rounded-full transition-all duration-300 ease-in-out group"
      style={{ '--color': color }}
      aria-label={`Switch to ${isItalian ? 'English' : 'Italian'}`}
    >
      <motion.div
        className="rounded-full p-2 flex items-center space-x-2 border-2"
        style={{
          backgroundColor: `${color}1A`, // 10% opacity
          borderColor: color,
          transition: 'box-shadow 0.1s linear'
        }}
        whileHover={{
          boxShadow: `0 0 10px 2px ${color}` // Using 40% opacity for shadow
        }}
      >
        <Globe style={{ color: color }} className="w-5 h-5" />
        <span style={{ color: color }} className="font-medium text-sm pr-1">
          {isItalian ? 'IT' : 'EN'}
        </span>
      </motion.div>
    </button>
  );
};

export default LanguageSwitch;